import axios from "axios";

const endpoint_url = "/campaignV2/customer/luckyDraw/participants";

const getLuckyDrawParticipants = ({ uuid, search, orderBy }) => {
  const query = { uuid };
  if (search) query.search = search;
  if (orderBy) query.orderBy = orderBy;
  const params = new URLSearchParams(query);

  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url, { params })
      .then((response) => resolve(response))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default getLuckyDrawParticipants;
