import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import { Skeleton } from "@material-ui/lab";
import moment from "moment";
import { ChevronRightRounded as ChevronRightRoundedIcon } from "@material-ui/icons";
import InstantWinDefaultBanner from "assets/img/instant_win_default_banner.png";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    borderRadius: 16,
    minHeight: 143,
    display: "flex",
    justifyContent: "space-between",
    scrollbarWidth: "thin",
    backgroundColor: "#ffffff",
    overflow: "hidden",
  },
  label: {
    background:
      "linear-gradient(90deg, #6BDA8E 0%, #4D98D9 34.8%, #7077F7 62.65%, #CB62F6 100%);",
    padding: "2px 8px",
    borderRadius: 8,
    textTransform: "uppercase",
    fontWeight: 900,
    color: "white",
    width: "fit-content",
    textShadow: "-1px 0 #67D099, 1px 0 #949BC8, 1px 0 #AA80DF, 1px 0 #C065F6",
  },
  bannerContainer: {
    borderRadius: 16,
    padding: 16,
    position: "relative",
    background: "linear-gradient(95.48deg, #1A385E 50.93%, #461E70 100%)",
    color: "white",
    overflow: "hidden",
  },
  bannerDefaultImg: {
    position: "absolute",
    width: "50%",
    height: "90%",
    right: -50,
    bottom: -50,
    "& img": {
      height: "100%",
      width: "100%",
      objectFit: "contain",
    },
  },
  defaultThemeBannerCountdown: {
    position: "absolute",
    right: 0,
    top: 8,
    backgroundColor: "#FFFFFFCC",
    color: "black",
    padding: "2px 12px",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  bannerImg: {
    width: "100%",
    height: "auto",
    borderRadius: 8,
  },
  bannerCountdown: {
    position: "absolute",
    right: 0,
    top: 10,
    backgroundColor: "#1A385EB2",
    color: "white",
    padding: "4px 12px",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "#00000091",
    width: "100%",
    height: "100%",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
}));

export default function InstantWinComponent({
  instantWin,
  lang,
  isLoading,
  countdown,
}) {
  const classes = useStyles();

  let isJoinStart = instantWin
    ? moment().isAfter(moment.utc(instantWin.join_open_at).local())
    : false;
  let isJoinEnd = instantWin?.join_close_at
    ? moment().isAfter(moment.utc(instantWin.join_close_at).local())
    : false;
  let isEnded = instantWin?.end_at
    ? moment().isAfter(moment.utc(instantWin.end_at).local())
    : false;

  let showCountdown =
    (countdown.day > 0 ||
      countdown.hour > 0 ||
      countdown.min > 0 ||
      countdown.sec > 0) &&
    !isEnded;

  return (
    <Box p={2}>
      <Box className={classes.container}>
        <Box style={{ width: "100%" }}>
          {isLoading ? (
            <Box
              p={2}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              style={{ width: "100%" }}
            >
              <Skeleton width={"100%"} height={30} />
              <Skeleton width={"100%"} height={30} />
              <Skeleton width={"100%"} height={30} />
              <Skeleton width={"100%"} height={30} />
            </Box>
          ) : instantWin ? (
            !instantWin.image_url ? (
              <Box className={classes.bannerContainer}>
                <Box className={classes.label}>
                  <Typography variant="h6" style={{ fontSize: 18 }}>
                    <b>{getLang(lang, "label.INSTANT_WIN")}</b>
                  </Typography>
                </Box>
                <Box style={{ width: "60%" }} mt={2}>
                  <Box mb={0.5}>
                    <Typography variant="h6">
                      <b>{instantWin.title}</b>
                    </Typography>
                  </Box>
                  <Typography variant="body2">
                    {instantWin.description}
                  </Typography>
                </Box>
                <Box
                  style={{ width: "60%" }}
                  display="flex"
                  alignItems="center"
                  mt={3}
                  mb={2}
                >
                  <Typography variant="body2">
                    <b>
                      {getLang(
                        lang,
                        isJoinStart ? "label.PLAY_NOW" : "label.EXPLORE_NOW"
                      )}
                    </b>
                  </Typography>
                  <Box
                    borderRadius={50}
                    ml={1}
                    height={19}
                    width={19}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      backgroundColor: "#FFFFFF80",
                      cursor: "pointer",
                    }}
                  >
                    <ChevronRightRoundedIcon style={{ color: "white" }} />
                  </Box>
                </Box>
                <Box className={classes.bannerDefaultImg}>
                  <img src={InstantWinDefaultBanner} alt="ld" />
                </Box>
                {showCountdown && (!isJoinStart || !isJoinEnd) && (
                  <Box className={classes.defaultThemeBannerCountdown}>
                    <Typography variant="caption">
                      {isJoinStart
                        ? getLang(lang, "label.ENDS_IN")
                        : getLang(lang, "label.STARTS_IN")}{" "}
                      <span>
                        <b>
                          {countdown.day}:{countdown.hour}:{countdown.min}:
                          {countdown.sec}
                        </b>
                      </span>
                    </Typography>
                  </Box>
                )}
                {isEnded && (
                  <Box className={classes.overlay}>
                    <Typography variant="body1">
                      {getLang(lang, "label.ENDED")}
                    </Typography>
                  </Box>
                )}
              </Box>
            ) : (
              <Box
                className={classes.bannerContainer}
                style={{ background: "white", color: "black" }}
              >
                <img
                  src={instantWin.image_url}
                  alt="instant-win-img"
                  className={classes.bannerImg}
                />
                {showCountdown && (!isJoinStart || !isJoinEnd) && (
                  <Box className={classes.bannerCountdown}>
                    <Typography variant="caption">
                      {isJoinStart
                        ? getLang(lang, "label.ENDS_IN")
                        : getLang(lang, "label.STARTS_IN")}{" "}
                      <span>
                        <b>
                          {countdown.day}:{countdown.hour}:{countdown.min}:
                          {countdown.sec}
                        </b>
                      </span>
                    </Typography>
                  </Box>
                )}
                <Box mt={1}>
                  <Box
                    display="flex"
                    alignItems="center"
                    mb={0.5}
                    flexWrap="wrap"
                  >
                    <Box mr={1} mt={0.5}>
                      <Typography variant="body1">
                        <b>{instantWin.title}</b>
                      </Typography>
                    </Box>
                    <Box className={classes.label} mt={0.5}>
                      <Typography variant="body2">
                        <b>{getLang(lang, "label.INSTANT_WIN")}</b>
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="body1">
                    {instantWin.description}
                  </Typography>
                </Box>
                <Box
                  style={{ width: "60%" }}
                  display="flex"
                  alignItems="center"
                  mt={2}
                  mb={1}
                >
                  <Typography variant="body2">
                    <b>
                      {getLang(
                        lang,
                        isJoinStart ? "label.PLAY_NOW" : "label.EXPLORE_NOW"
                      )}
                    </b>
                  </Typography>
                  <Box
                    borderRadius={50}
                    ml={1}
                    height={19}
                    width={19}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      backgroundColor: "#1A385EB2",
                      cursor: "pointer",
                    }}
                  >
                    <ChevronRightRoundedIcon style={{ color: "white" }} />
                  </Box>
                </Box>
                {isEnded && (
                  <Box className={classes.overlay}>
                    <Typography variant="body1">
                      {getLang(lang, "label.ENDED")}
                    </Typography>
                  </Box>
                )}
              </Box>
            )
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}
