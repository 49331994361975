/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import InstantWinComponent from "./instantWin.component";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import instantWinApi from "app/api/instantWin";
import { addAlert } from "modules/notification";
import { getApiLang } from "app/feature/constants";

export default function InstantWinContainer({ id }) {
  const dispatch = useDispatch();
  const { isVisible, content } = useSelector(
    (state) => state.productPage.components[id]
  );
  const lang = useSelector((state) => state.constant.languages);

  const [isLoading, setIsLoading] = useState(false);
  const [instantWin, setInstantWin] = useState(null);
  const [countdown, setCountdown] = useState({
    day: 0,
    hour: 0,
    min: 0,
    sec: 0,
  });

  const intervalRef = useRef(null);

  useEffect(() => {
    if (content.id) {
      setIsLoading(true);
      instantWinApi
        .getInstantWinCardDetail({ id: content.id })
        .then((res) => {
          setInstantWin(res.data);
        })
        .catch((error) => {
          dispatch(
            addAlert({
              severity: "error",
              message: getApiLang(lang, error.code),
            })
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  useEffect(() => {
    if (instantWin) {
      let isStart = moment().isAfter(
        moment.utc(instantWin.join_open_at).local()
      );
      if (!isStart) {
        checkJoinStartCountdown(instantWin);
      } else {
        checkJoinCloseCountdown(instantWin);
      }
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [instantWin]);

  const checkJoinStartCountdown = (instantWin) => {
    intervalRef.current = setInterval(() => {
      let tempCountdown = updateCountdown({
        targetDate: moment.utc(instantWin.join_open_at).local(),
      });
      setCountdown(tempCountdown);

      let countdownEnd =
        !tempCountdown.day &&
        !tempCountdown.hour &&
        !tempCountdown.min &&
        !tempCountdown.sec;
      if (countdownEnd && intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      if (countdownEnd && instantWin.join_close_at) {
        checkJoinCloseCountdown(instantWin);
      }
    }, 1000);
  };

  const checkJoinCloseCountdown = (instantWin) => {
    if (!!instantWin.join_close_at) {
      let isJoinEnd = moment().isAfter(
        moment.utc(instantWin.join_close_at).local()
      );
      if (!isJoinEnd) {
        intervalRef.current = setInterval(() => {
          let tempCountdown = updateCountdown({
            targetDate: moment.utc(instantWin.join_close_at).local(),
          });
          setCountdown(tempCountdown);

          let countdownEnd =
            !tempCountdown.day &&
            !tempCountdown.hour &&
            !tempCountdown.min &&
            !tempCountdown.sec;
          if (countdownEnd && intervalRef.current) {
            clearInterval(intervalRef.current);
          }
        }, 1000);
      }
    }
  };

  const updateCountdown = ({ targetDate }) => {
    var seconds = Math.floor(
      moment.duration(targetDate.diff(moment())).asSeconds()
    );
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);

    return {
      day: days > 0 ? days : 0,
      hour: hours - days * 24,
      min: minutes - hours * 60,
      sec: seconds - minutes * 60,
    };
  };

  return (
    isVisible && (
      <InstantWinComponent
        instantWin={instantWin}
        lang={lang}
        isLoading={isLoading}
        countdown={countdown}
      />
    )
  );
}
