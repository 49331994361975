export const transferStatus = {
  pendingApproval: "Pending Approval",
  pendingReceive: "Pending To Receive",
  partialReceived: "Partial Received",
  received: "Received",
  rejected: "Rejected",
  rejectApproval: "Reject Approval",
  cancelled: "Cancelled",
  autoReceived: "Auto Received",
  autoReceivedPartial: "Auto Received (Partial)",
  autoReceivedMissing: "Auto Received (Missing)",
};
