/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import TransferProductSnListDialogComponent from "./transferProductSnListDialog.component";
import { useDispatch, useSelector } from "react-redux";
import { selectLoading } from "modules/notification";
import {
  getReceiveTransferItemProductSNList,
  getTransferItemProductSNList,
  resetSnListing,
  resetSnListingReceiver,
} from "modules/stockflow/redux";
import { generatePaginationOptions } from "lib/helper";
import { transferStatus } from "modules/stockflow/utils/constants";

function TransferProductSnListDialogContainer({
  isOpen,
  handleClose,
  boxUuid,
  id,
  productInfo,
  isReceiver,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);

  const isFetching = useSelector((state) =>
    selectLoading(
      state,
      isReceiver
        ? getReceiveTransferItemProductSNList.typePrefix
        : getTransferItemProductSNList.typePrefix
    )
  );
  const totalFiltered = useSelector(
    (state) =>
      state[isReceiver ? "stockflowReceiveTransfer" : "stockflowTransfer"]
        .transferSnList.totalFiltered
  );
  const list = useSelector(
    (state) =>
      state[isReceiver ? "stockflowReceiveTransfer" : "stockflowTransfer"]
        .transferSnList.list
  );
  const isError = useSelector(
    (state) =>
      state[isReceiver ? "stockflowReceiveTransfer" : "stockflowTransfer"]
        .transferSnList.isError
  );

  const [tab, setTab] = useState(0);

  const [paginationOptions, setPaginationOptions] = useState([]);
  const [tableFilter, setTableFilter] = useState({
    page: 0,
    rowsPerPage: 25,
    order: "asc",
    orderBy: "sequence_number", // sequence_number|code
    status: "",
  });

  useEffect(() => {
    return () => {
      dispatch(isReceiver ? resetSnListingReceiver() : resetSnListing());
    };
  }, [dispatch]);

  useEffect(() => {
    if (productInfo) {
      handleDataLoad();
    }
  }, [tableFilter, productInfo]);

  useEffect(() => {
    if (!isOpen) {
      setTableFilter({
        page: 0,
        rowsPerPage: 25,
        order: "asc",
        orderBy: "sequence_number",
        status: "",
        search: "",
      });
      dispatch(isReceiver ? resetSnListingReceiver() : resetSnListing());
    }
  }, [isOpen]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(totalFiltered));
  }, [totalFiltered]);

  const handleDataLoad = () => {
    let status = "";
    if (
      [
        transferStatus.partialReceived,
        transferStatus.received,
        transferStatus.autoReceived,
        transferStatus.autoReceivedPartial,
        transferStatus.autoReceivedMissing,
      ].includes(productInfo.status)
    ) {
      status =
        tab === 0
          ? "receive"
          : productInfo.status === transferStatus.partialReceived
          ? "pending"
          : "missing";
    }

    let payload = {
      id: id,
      product_uuid: productInfo.uuid,
      length: tableFilter.rowsPerPage,
      start: tableFilter.rowsPerPage * tableFilter.page,
      order: tableFilter.order,
      order_by: tableFilter.orderBy,
      status: status,
      box_uuid: boxUuid,
    };
    dispatch(
      isReceiver
        ? getReceiveTransferItemProductSNList(payload)
        : getTransferItemProductSNList(payload)
    );
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...tableFilter,
      page: newPage,
    };
    setTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...tableFilter,
      page: 0,
      rowsPerPage: event.target.value,
    };
    setTableFilter(newData);
  };

  const changeOrder = (orderBy, order) => {
    let newData = {
      ...tableFilter,
      order,
      orderBy,
    };
    setTableFilter(newData);
  };

  const handleReload = () => {
    let newData = {
      ...tableFilter,
      page: 0,
    };
    setTableFilter(newData);
  };

  const handleChangeTab = (event, tabIndex) => {
    if (tabIndex !== tab) {
      setTab(tabIndex);
      let newData = {
        ...tableFilter,
        page: 0,
      };
      setTableFilter(newData);
    }
  };

  return (
    <TransferProductSnListDialogComponent
      isOpen={isOpen}
      handleClose={() => {
        setTab(0);
        handleClose();
      }}
      lang={lang}
      productInfo={productInfo}
      list={list}
      isFetching={isFetching}
      isError={isError}
      page={tableFilter.page}
      rowsPerPage={tableFilter.rowsPerPage}
      totalFiltered={totalFiltered}
      paginationOptions={paginationOptions}
      changeOrder={changeOrder}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      tableFilter={tableFilter}
      handleReload={handleReload}
      tab={tab}
      handleChangeTab={handleChangeTab}
    />
  );
}

export default TransferProductSnListDialogContainer;
