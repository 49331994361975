import React, { useState, useRef, useEffect } from "react";
import InstantWinSelectComponent from "./instantWinSelect.component";
import InstantWinSelectPanelComponent from "./instantWinSelectPanel.component";
import { addAlert } from "modules/notification";
import { useDispatch, useSelector } from "react-redux";
import instantWinApi from "app/api/instantWin";
import { getApiLang } from "app/feature/constants";

function InstantWinSelectContainer({
  placeholder,
  handleChange,
  value,
  disabled,
  styles,
  searchPlaceholder,
  searchable,
  textStyles,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const productId = useSelector((state) => state.productPage.productId);
  const components = useSelector((state) => state.productPage.components);

  const [open, setOpen] = useState(false);
  const [isOpenList] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const prevOpenList = useRef(isOpenList);

  const [list, setList] = useState([]);
  const [totalFiltered, setTotalFiltered] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const [filter, setFilter] = useState({
    page: 0,
    rowsPerPage: 30,
    search: "",
  });

  const [instantWinList, setInstantWinList] = useState([]);

  useEffect(() => {
    let temp = [...list];
    let instantComp = Object.keys(components).filter((c) =>
      c.startsWith("instant win")
    );
    if (instantComp) {
      let usedId = [];
      instantComp.forEach((key) => {
        let comp = components[key];
        if (comp?.content?.id && comp.content.id !== value) {
          usedId.push(comp.content.id);
        }
      });
      temp = temp.filter((t) => !usedId.includes(t.uuid));
    }

    setInstantWinList(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, components]);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpenList.current === true && isOpenList === false) {
      anchorRef.current.focus();
    }

    prevOpenList.current = isOpenList;
  }, [isOpenList]);

  useEffect(() => {
    // filtered should be a reset, has next page validation not needed 
    // if (hasNextPage && !isFetching) {
    if (!isFetching) {
      getData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, dispatch]);

  const getData = () => {
    setIsFetching(true);
    let param = {
      length: filter.rowsPerPage,
      start: filter.rowsPerPage * filter.page,
      search: filter.search,
      product_uuid: productId,
    };

    instantWinApi
      .getAvailableInstantWinDropdown(param)
      .then((res) => {
        let temp = [...list, ...res.data.data];
        setList(temp);
        setTotalFiltered(res.data.records_filtered);
        setHasNextPage(temp.length < res.data.records_filtered);
      })
      .catch((error) => {
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, error.code),
          })
        );
        setIsError(true);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const handleSearch = (search) => {
    setList([]);
    setFilter({
      ...filter,
      page: 0,
      search: search,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleFetchMoreItems = () => {
    if (hasNextPage) {
      const newFilter = { ...filter, page: filter.page + 1 };
      setFilter(newFilter);
    }
  };

  const handleReload = () => {
    setList([]);
    setFilter({
      ...filter,
      page: 0,
      search: "",
    });
  };

  const handleSelectInstantWin = (instantWin) => {
    handleChange(instantWin);
    handleClose();
  };

  return (
    <>
      <InstantWinSelectComponent
        anchorRef={anchorRef}
        handleClick={handleClick}
        value={value}
        styles={styles}
        placeholder={placeholder}
        disabled={disabled}
        textStyles={textStyles}
        lang={lang}
        instantWins={instantWinList}
      />
      <InstantWinSelectPanelComponent
        value={value}
        anchorRef={anchorRef}
        open={open}
        handleClose={handleClose}
        isFetching={isFetching}
        instantWins={instantWinList}
        handleSelectInstantWin={handleSelectInstantWin}
        searchable={searchable}
        placeholder={searchPlaceholder}
        handleSearch={handleSearch}
        lang={lang}
        totalFiltered={totalFiltered}
        handleFetchMoreItems={handleFetchMoreItems}
        hasNextPage={hasNextPage}
        isError={isError}
        handleReload={handleReload}
        currentTotal={instantWinList.length}
      />
    </>
  );
}

export default InstantWinSelectContainer;
