import getLuckyDraws from "./getLuckyDraws";
import createLuckyDraw from "./createLuckyDraw";
import updateLuckyDraw from "./updateLuckyDraw";
import updatePeriod from "./updatePeriod";
import updatePrize from "./updatePrize";
import getLuckyDrawDetails from "./getLuckyDrawDetails";
import duplicateLuckyDraws from "../options/duplicateLuckyDraws.api";
import updateParticipantForm from "./updateParticipantForm";
import publishLuckyDraw from "./publishLuckyDraw.api";
import updateLiveDrawSetup from "./updateLiveDrawSetup.api";
import drawLuckyDraw from "./drawLuckyDraw.api";
import getLuckyDrawLiveDrawInfo from "./getLuckyDrawLiveDrawInfo.api";
import updateWinRate from "./updateWinRate";
import deleteLuckyDraw from "./deleteLuckyDraw";
import getLuckyDrawParticipants from "./getLuckyDrawParticipants";
import getLuckyDrawParticipantDetails from "./getLuckyDrawParticipantDetails";
import exportParticipantsList from "./getWinnerList.api";
import updateStatus from "./updateStatus";
import luckyDrawBinding from "./luckyDrawBinding";
import getLuckyDrawBindingInfo from "./getLuckyDrawBindingInfo";
import exportWinnerList from "./exportWinnerList";
import getPrizes from "./getPrizes.api";
import getAllSerialNumber from "./getAllSerialNumber.api";
import getSerialNumberList from "./getSerialNumberList.api";
import presetWinner from "./presetWinner.api";
import endLuckyDraw from "./endLuckyDraw";
import endLuckyDrawJoinPeriod from "./endLuckyDrawJoinPeriod";
import getProductNodes from "./getProductNodes.api";
import getLuckyDrawCardDetail from "./getLuckyDrawCardDetail.api";

const luckyDrawV2Api = {
  getLuckyDraws,
  createLuckyDraw,
  updateLuckyDraw,
  updatePeriod,
  updatePrize,
  getLuckyDrawDetails,
  duplicateLuckyDraws,
  updateParticipantForm,
  publishLuckyDraw,
  updateLiveDrawSetup,
  drawLuckyDraw,
  getLuckyDrawLiveDrawInfo,
  updateWinRate,
  deleteLuckyDraw,
  getLuckyDrawParticipants,
  getLuckyDrawParticipantDetails,
  exportParticipantsList,
  updateStatus,
  luckyDrawBinding,
  getLuckyDrawBindingInfo,
  exportWinnerList,
  presetWinner,
  getPrizes,
  getAllSerialNumber,
  getSerialNumberList,
  endLuckyDraw,
  endLuckyDrawJoinPeriod,
  getProductNodes,
  getLuckyDrawCardDetail
};

export default luckyDrawV2Api;
