import React, { useEffect, useState } from "react";
import DetailsComponent from "./details.page";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  SERIAL_NUMBER_EDIT,
  SERIAL_NUMBER_VIEW,
} from "lib/constants/accessRights";
import { ROOT } from "../../config/serial-number.route";
import {
  getDetailsSerialNumber,
  getSerialNumberActivityLog,
  resetSNScanCount,
} from "../../redux/action";
import { addAlert, selectLoading } from "modules/notification";
import LoadingComponent from "components/loading";
import GeneralDialog from "components/dialog/generalDialog";
import Alert from "assets/svg/alert.svg";
import { resetSerialNumberDetail } from "modules/serial-number/redux";
import { getLang } from "app/feature/constants";
import UpdateSnAdtInfoDialog from "modules/serial-number/components/dialog/updateSnAdtInfoDialog";

function DetailsContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const match = useRouteMatch();
  const enc = match.params.enc;
  const hasSerialNumberEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(SERIAL_NUMBER_EDIT)
  );
  const hasSerialNumberViewAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(SERIAL_NUMBER_VIEW)
  );
  const serialNumber = useSelector((state) => state.serialNumber.serialNumber);
  const isLoading = useSelector((state) =>
    selectLoading(state, getDetailsSerialNumber.typePrefix)
  );
  const resetCountIsLoading = useSelector((state) =>
    selectLoading(state, resetSNScanCount.typePrefix)
  );
  const userLang = useSelector((state) => state.profile.language);

  const [resetConfirmationDialogModal, setResetConfirmationDialogModal] =
    useState(false);
  const [showMoreDetail, setShowMoreDetail] = useState(false);
  const [showEditAdtDialog, setShowEditAdtDialog] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetSerialNumberDetail());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!hasSerialNumberViewAccessRight) {
      history.push("/admin/dashboard");
    }
  }, [history, hasSerialNumberViewAccessRight]);

  useEffect(() => {
    if (enc) {
      dispatch(getDetailsSerialNumber({ enc }));
      dispatch(getSerialNumberActivityLog(enc));
    } else {
      history.push(ROOT);
    }
  }, [history, enc, dispatch]);

  if (!hasSerialNumberViewAccessRight) return <LoadingComponent />;

  let tabLabels = [];
  if (hasSerialNumberViewAccessRight) {
    tabLabels.push(getLang(lang, "label.DETAILS"));
  }

  const handleCopy = (urlPrefix) => {
    navigator.clipboard.writeText(`${urlPrefix}${enc}`);
    dispatch(
      addAlert({
        severity: "success",
        message: getLang(lang, "message.success.LINK_COPIED_TO_CLIPBOARD"),
      })
    );
  };

  const resetCount = () => {
    dispatch(resetSNScanCount({ enc: enc })).then(() =>
      setResetConfirmationDialogModal(false)
    );
  };

  const handleResetCount = () => {
    setResetConfirmationDialogModal(true);
  };

  const hideEditAdtDialog = () => {
    setShowEditAdtDialog(false);
  }

  return (
    <>
      <DetailsComponent
        serialNumber={serialNumber}
        isLoading={isLoading}
        handleCopy={handleCopy}
        handleResetCount={handleResetCount}
        hasSerialNumberEditAccessRight={hasSerialNumberEditAccessRight}
        showMoreDetail={showMoreDetail}
        setShowMoreDetail={setShowMoreDetail}
        lang={lang}
        userLang={userLang}
        handleEditAdtInfo={() => setShowEditAdtDialog(true)}
        hideEditAdtDialog={hideEditAdtDialog}
      />
      <UpdateSnAdtInfoDialog
        enc={enc}
        isOpen={showEditAdtDialog}
        handleClose={() => setShowEditAdtDialog(false)}
        additionalInfos={serialNumber.info.additionalInfos}
      />
      <GeneralDialog
        isLoading={resetCountIsLoading}
        isOpen={resetConfirmationDialogModal}
        handleClose={() => setResetConfirmationDialogModal(false)}
        handleProceed={resetCount}
        title={getLang(lang, "label.RESET_COUNT")}
        description={getLang(lang, "paragraph.RESET_COUNT_QUESTION")}
        icon={<img src={Alert} alt="" />}
        type={"danger"}
        lang={lang}
      />
    </>
  );
}

export default DetailsContainer;
