import React from "react";
import {
  makeStyles,
  Typography,
  Box,
  Divider,
  Chip,
  Paper,
  Button,
  Collapse,
} from "@material-ui/core";
import {
  ChevronRightRounded as ChevronRightRoundedIcon,
  ExpandMore,
  ExpandLess,
  Edit as EditIcon,
} from "@material-ui/icons";
import { BackButton } from "components/button";
import { Skeleton } from "@material-ui/lab";
import DefaultImg from "assets/img/img-default.png";
import clsx from "clsx";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import ScanHistoryPanel from "modules/serial-number/components/panel/scanHistoryPanel";
import CampaignAssignListPanel from "modules/serial-number/components/panel/campaignAssignListPanel";
import ProductTraceabilityPanel from "modules/serial-number/components/panel/productTraceabilityPanel";
import { useHistory } from "react-router-dom";
import { ROOT as PRODUCT_ROOT } from "modules/product-mgmt";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
  },
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
  },
  scroll: {
    width: "min-content",
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #F2F4F7",
    width: 140,
    height: 140,
    borderRadius: 20,
    marginRight: 10,
    minWidth: 140,
    "& img": {
      height: "100%",
      width: "100%",
      borderRadius: 20,
    },
  },
  activeChipStatus: {
    backgroundColor: "#ECFDF3",
    width: "fit-content",
    textTransform: "capitalize",
    color: "#027A48",
    fontWeight: "bold",
  },
  suspendChipStatus: {
    backgroundColor: "#fdecec",
    width: "fit-content",
    textTransform: "capitalize",
    color: "red",
    fontWeight: "bold",
  },
  missingChipStatus: {
    backgroundColor: "#fff4e0",
    width: "fit-content",
    textTransform: "capitalize",
    color: "#FDB022",
    fontWeight: "bold",
  },
  disabledChipStatus: {
    backgroundColor: "#F2F4F7",
    width: "fit-content",
    textTransform: "capitalize",
    color: "#475467",
    fontWeight: "bold",
  },
  lockedChipStatus: {
    backgroundColor: "#D3D3D3",
    width: "fit-content",
    textTransform: "capitalize",
    color: "#808080",
    fontWeight: "bold",
  },
  copyButton: {
    color: "#98A2B3",
    border: "2px solid #D0D5DD",
  },
  resetButton: {
    backgroundColor: "#F97066",
    color: "white",
  },
  button: {
    borderRadius: 8,
    textTransform: "capitalize",
    marginTop: 5,
  },
  infoCard: {
    display: "grid",
    gridTemplateColumns: "1.7fr 1fr",
    "& >div:last-child": {
      borderLeft: "2px solid #e6e6e6",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      "& >div:last-child": {
        borderTop: "2px solid #e6e6e6",
        paddingTop: 10,
        marginTop: 16,
        borderLeft: "none",
      },
    },
  },
  detailContainer: {
    display: "grid",
    overflow: "auto",
    gridTemplateColumns: "1fr 1fr",
    "& >div:last-child": {
      borderLeft: "2px solid #e6e6e6",
      paddingLeft: 12,
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      "& >div:last-child": {
        borderLeft: "none",
        paddingLeft: 0,
      },
    },
  },
  field: {
    display: "flex",
    marginBottom: 4,
  },
  floatButton: {
    background: "white",
    borderRadius: 50,
    padding: "0px 16px",
    boxShadow: "3px 2px 5px 1px #0000001f",
    cursor: "pointer",
    "& svg": {
      color: "#98A2B3",
    },
  },
}));

export default function GenerateComponent({
  serialNumber,
  isLoading,
  handleCopy,
  handleResetCount,
  hasSerialNumberEditAccessRight,
  showMoreDetail,
  setShowMoreDetail,
  lang,
  userLang,
  handleEditAdtInfo,
  hideEditAdtDialog
}) {
  const classes = useStyle();
  const history = useHistory();

  return (
    <Box className={classes.root}>
      <Box gridArea="topbar">
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="flex-end" fontWeight="bold">
            <BackButton />
            <Typography variant="h6" color="primary" className={classes.title}>
              {getLang(lang, "label.SERIAL_NUMBER")}
            </Typography>
            <Box mx={0.5}>
              <ChevronRightRoundedIcon color="disabled" />
            </Box>
            <Box>
              <Typography variant="h6" color="primary">
                {isLoading ? (
                  <Skeleton animation="wave" style={{ width: 125 }} />
                ) : (
                  serialNumber.info?.serialNumber || "-"
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
      </Box>
      <Box gridArea="panel" mt={2} mb={3}>
        <Paper>
          <Box p={2}>
            {!isLoading ? (
              <Box position="relative">
                <Box className={classes.infoCard}>
                  <Box display="flex" alignItems="center">
                    <Box className={classes.productPhoto}>
                      {serialNumber.info.productImage ? (
                        <img
                          src={serialNumber.info.productImage}
                          alt={serialNumber.info.productName}
                        />
                      ) : (
                        <img src={DefaultImg} alt="emptyProduct" />
                      )}
                    </Box>
                    <Box>
                      <Box
                        mb={1}
                        display="flex"
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        <Typography variant="h5" style={{ marginRight: 8 }}>
                          <b>{serialNumber.info.serialNumber}</b>
                        </Typography>
                        <Button
                          variant="outlined"
                          onClick={() =>
                            handleCopy(serialNumber.info.urlPrefix)
                          }
                          startIcon={<FileCopyOutlinedIcon />}
                          className={classes.copyButton}
                          disabled={serialNumber.card.status === "locked"}
                        >
                          {getLang(lang, "label.COPY_URL")}
                        </Button>
                      </Box>
                      <Typography variant="body1" color="textSecondary">
                        {serialNumber.info.productName}
                      </Typography>
                      <Chip
                        label={`• ${
                          serialNumber.card.status === "suspend"
                            ? getLang(lang, "label.SUSPEND")
                            : serialNumber.card.status === "active"
                            ? getLang(lang, "label.ACTIVE")
                            : serialNumber.card.status === "disabled"
                            ? getLang(lang, "label.DISABLED")
                            : serialNumber.card.status === "missing"
                            ? getLang(lang, "label.MISSING")
                            : serialNumber.card.status === "faulty"
                            ? getLang(lang, "label.FAULTY")
                            : serialNumber.card.status === "locked"
                            ? getLang(lang, "label.LOCKED")
                            : serialNumber.card.status
                        }`}
                        size="small"
                        className={clsx({
                          [classes.activeChipStatus]:
                            serialNumber.card.status === "active",
                          [classes.suspendChipStatus]:
                            serialNumber.card.status === "suspend",
                          [classes.missingChipStatus]:
                            serialNumber.card.status === "missing" ||
                            serialNumber.card.status === "faulty",
                          [classes.disabledChipStatus]:
                            serialNumber.card.status === "disabled",
                          [classes.lockedChipStatus]:
                            serialNumber.card.status === "locked",
                        })}
                        style={{ marginTop: 8 }}
                      />
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-around"
                    alignItems="center"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Typography variant="h5">
                        <b>
                          {serialNumber.card.scanCount.toLocaleString("en-US")}
                        </b>
                      </Typography>
                      <Typography variant="body2">
                        {getLang(lang, "label.TOTAL_SCAN_COUNT")}
                      </Typography>
                      {!!hasSerialNumberEditAccessRight && (
                        <Button
                          variant="contained"
                          onClick={handleResetCount}
                          color="primary"
                          className={clsx(classes.button, classes.resetButton)}
                          disabled={serialNumber.card.status === "locked"}
                        >
                          {getLang(lang, "label.RESET_SCAN_COUNT")}
                        </Button>
                      )}
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Typography variant="h5">
                        <b>
                          {serialNumber.card.scanLimit.toLocaleString("en-US")}
                        </b>
                      </Typography>
                      <Typography variant="body2">
                        {getLang(lang, "label.SCAN_LIMIT")}
                      </Typography>
                      {!!hasSerialNumberEditAccessRight &&
                        serialNumber.card.status !== "replace" && (
                          <Button
                            variant="outlined"
                            onClick={() => {
                              history.push(
                                `${PRODUCT_ROOT}/${serialNumber.info.productUuid}`
                              );
                            }}
                            className={classes.button}
                            disabled={serialNumber.card.status === "locked"}
                          >
                            {getLang(lang, "label.EDIT_SCAN_LIMIT")}
                          </Button>
                        )}
                    </Box>
                  </Box>
                </Box>
                <Collapse in={showMoreDetail}>
                  <Box mt={2}>
                    <Divider />
                    <Box className={classes.detailContainer} mt={2}>
                      <Box>
                        <Box className={classes.field}>
                          <Typography>
                            {getLang(lang, "label.BRANCH_ASSIGNED")}
                          </Typography>
                          <Box display="flex">
                            <Typography
                              color="textSecondary"
                              style={{ marginRight: 5 }}
                            >
                              :
                            </Typography>
                            <Box>
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                style={{ textAlign: "left" }}
                              >
                                {serialNumber.info.branchName || "-"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box className={classes.field}>
                          <Typography>
                            {getLang(lang, "label.CATEGORY")}
                          </Typography>
                          <Box display="flex">
                            <Typography
                              color="textSecondary"
                              style={{ marginRight: 5 }}
                            >
                              :
                            </Typography>
                            <Box>
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                style={{ textAlign: "left" }}
                              >
                                {serialNumber.info.product?.category || "-"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box className={classes.field}>
                          <Typography>
                            {getLang(lang, "label.BRAND")}
                          </Typography>
                          <Box display="flex">
                            <Typography
                              color="textSecondary"
                              style={{ marginRight: 5 }}
                            >
                              :
                            </Typography>
                            <Box>
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                style={{ textAlign: "left" }}
                              >
                                {serialNumber.info.product?.brand || "-"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box className={classes.field}>
                          <Typography>
                            {getLang(lang, "label.MODEL")}
                          </Typography>
                          <Box display="flex">
                            <Typography
                              color="textSecondary"
                              style={{ marginRight: 5 }}
                            >
                              :
                            </Typography>
                            <Box>
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                style={{ textAlign: "left" }}
                              >
                                {serialNumber.info.product?.model || "-"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Box className={classes.field}>
                          <Typography>
                            {getLang(lang, "label.ATTRIBUTES")}
                          </Typography>
                          <Box display="flex">
                            <Typography
                              color="textSecondary"
                              style={{ marginRight: 5 }}
                            >
                              :
                            </Typography>
                            <Box>
                              {serialNumber.info.product?.attributeoptions
                                ?.length ? (
                                serialNumber.info.product.attributeoptions.map(
                                  (a, i) => (
                                    <Box key={i}>
                                      <Typography
                                        variant="body1"
                                        color="textSecondary"
                                        style={{ textAlign: "left" }}
                                      >
                                        {a.name}: {a.value}
                                      </Typography>
                                    </Box>
                                  )
                                )
                              ) : (
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  -
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        </Box>
                        <Box className={classes.field}>
                          <Typography>
                            {getLang(lang, "label.PRICE")}
                          </Typography>
                          <Box display="flex">
                            <Typography
                              color="textSecondary"
                              style={{ marginRight: 5 }}
                            >
                              :
                            </Typography>
                            <Box>
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                style={{ textAlign: "left" }}
                              >
                                {serialNumber.info.product?.currency_code}{" "}
                                {serialNumber.info.product
                                  ?.unit_price_formatted || "0"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box className={classes.field}>
                          <Typography>{getLang(lang, "label.SKU")}</Typography>
                          <Box display="flex">
                            <Typography
                              color="textSecondary"
                              style={{ marginRight: 5 }}
                            >
                              :
                            </Typography>
                            <Box>
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                style={{ textAlign: "left" }}
                              >
                                {serialNumber.info.product?.sku || "-"}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box py={1}>
                      <Divider />
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      <Box>
                        <Box mb={1}>
                          <Typography variant="body1">
                            <u>
                              <b>{getLang(lang, "label.ADDITIONAL_INFO")}</b>
                            </u>
                          </Typography>
                        </Box>
                        {serialNumber.info.additionalInfos.length ? (
                          serialNumber.info.additionalInfos.map((a, i) => (
                            <Box className={classes.field} key={i}>
                              <Typography>
                                {a.title[userLang] ??
                                  a.title["EN"] ??
                                  Object.values(a.title)[0]}
                              </Typography>
                              <Box display="flex">
                                <Typography
                                  color="textSecondary"
                                  style={{ marginRight: 5 }}
                                >
                                  :
                                </Typography>
                                <Box>
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    style={{ textAlign: "left" }}
                                  >
                                    {a.format === 1
                                      ? a.value[userLang] ??
                                        a.value["EN"] ??
                                        Object.values(a.title)[0]
                                      : a.value}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          ))
                        ) : (
                          <Box>
                            <Typography>
                              {getLang(
                                lang,
                                "paragraph.NO_ADDITIONAL_INFO_YET"
                              )}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                      <Box>
                        <EditIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (serialNumber.card.status === "locked") {
                              hideEditAdtDialog();
                            } else {
                              handleEditAdtInfo();
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Collapse>
                <Box
                  display="flex"
                  justifyContent="center"
                  position="absolute"
                  bottom={-30}
                  width="100%"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.floatButton}
                    onClick={() => {
                      setShowMoreDetail(!showMoreDetail);
                    }}
                  >
                    <Typography variant="body2" color="textSecondary">
                      {getLang(
                        lang,
                        showMoreDetail
                          ? "label.HIDE_PRODUCT_DETAILS"
                          : "label.MORE_PRODUCT_DETAILS"
                      )}
                    </Typography>
                    <Box>
                      {showMoreDetail ? <ExpandLess /> : <ExpandMore />}
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              <>
                <Box display="flex" flex={1} pr={2} alignItems="center">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mr={1}
                  >
                    <Skeleton variant="rect" width={140} height={140} />
                  </Box>
                  <Box flex={1}>
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                  </Box>
                </Box>
                <Box
                  pl={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box flex={1} mr={1}>
                    <Skeleton variant="text" height={40} mt={2} />
                    <Skeleton variant="text" height={40} mt={2} />
                  </Box>
                  <Box flex={1}>
                    <Skeleton variant="text" height={40} mt={2} />
                    <Skeleton variant="text" height={40} mt={2} />
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Paper>
      </Box>
      <ProductTraceabilityPanel />
      <CampaignAssignListPanel style={{ overflowX: "auto" }} />
      <ScanHistoryPanel />
    </Box>
  );
}
